import React from 'react';
import { styles } from '../css/styles';
const Home = () => {
  return (
    <div style={styles.page}>
      <h1>Canada PE Ltd.</h1>
      <p>
        Founded in 2006, Canada PE Ltd. evaluates oil and gas properties and independently certifies petroleum reserves quantities in Canada. The company offers a broad range of services, including reserve-based bank loan audits, assessments of the marketable value of assets for various industries such as restaurants, supermarkets, clinics, hotels, and manufacturing facilities, as well as bookkeeping, accounting, board advice, and management consulting.
      </p>
      <p>
        Additionally, we provide petroleum engineering services and supply barite (drilling mud), proppant, fracturing sand, and chemicals to the oil and gas drilling industry.
      </p>
    </div>
  );
};

export default Home;
