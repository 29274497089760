import React from 'react';
import { styles } from '../css/styles';



const Products = () => {
  return (
    <div style={styles.page}>
      <div>

        <h3>Barite</h3>
        <p>
          <strong>Chemical Composition:</strong> {'>'}90% BaSO4; &lt;1 ppm Hg; &lt;3 ppm Cd <br />
          <strong>Specific Gravity:</strong> &gt;4.23 <br />
          <strong>Application:</strong> Drilling Mud <br />
          <strong>Size:</strong> 6-75um &gt;80% <br />
          <strong>Quantities provide:</strong> 5000 ton/Month <br />
        </p>
      </div>

      <div>

        <h3>Silica Sand</h3>
        <p>
          <strong>Chemical Composition:</strong> {'>'}99.5% SiO2 <br />
          <strong>Application:</strong> Oil & shale gas fracturing <br />
          <strong>Shape:</strong> Spherical <br />
          <strong>Size:</strong> 20/40, 40/70, 70/100 <br />
          <strong>Quantities provide:</strong> 10,000 ton/month <br />
        </p>
      </div>

      <div>
      
        <h3>Calcined Bauxite proppant</h3>
        <p>
          <strong>Chemical Composition:</strong> {'>'}80% Al2O3 <br />
          <strong>Application:</strong> Oil & Gas deep well fracturing <br />
          <strong>Shape:</strong> Spherical <br />
          <strong>Size:</strong> 20/40, 40/70, 70/100 <br />
          <strong>Quantities provide:</strong> 5,000 ton/month <br />
        </p>
      </div>

      <div>

        <h3>Oil Sand net</h3>
        <p>
          <strong>Chemical Composition:</strong> PE <br />
          <strong>Application:</strong> Oil sand seismic <br />
          <strong>Size:</strong> 6-12mesh <br />
          <strong>Quantities provide:</strong> 100 ton/Month <br />
        </p>
      </div>
    </div>
  );
};

export default Products;
