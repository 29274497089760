import React from 'react';
import { styles } from '../css/styles';

const Services = () => {
  return (
    <div style={styles.page}>
      <h2>About Us</h2>
      <p>
        Canada PE Ltd. is a leading evaluation and consulting firm specializing in comprehensive asset valuation and financial analysis services across various industries. With a commitment to accuracy and reliability, our team of experienced professionals is dedicated to helping businesses understand and maximize their assets' and financial options' value.
      </p>

      <h2>Our Services</h2>
      <ul>
        <li><strong>Oil and Gas Industry Reserves Reports:</strong> We provide detailed evaluations of reserves for the oil and gas sector, ensuring compliance with industry standards and aiding in investment and financing decisions.</li>
        <li><strong>Marketable Value Assessments for General Industry:</strong> Whether you operate in the restaurant, hotel, clinic, supermarket, manufacturing, or any business sector, our marketable value assessments deliver essential information for financial reporting, insurance, and sale transactions.</li>
        <li><strong>Bank Lending Audit Reports:</strong> We specialize in conducting thorough audits for bank lending purposes, helping institutions evaluate the financial health and risk associated with lending to various businesses.</li>
        <li><strong>Credit Line Evaluation:</strong> Our services include analyzing and determining appropriate lines of credit for businesses based on comprehensive financial assessments and market conditions.</li>
        <li><strong>Market Sensitivity Analysis:</strong> We assess market factors' sensitivity, helping businesses understand potential impacts on their operations and financial stability.</li>
        <li><strong>Consulting Services:</strong> Our experts provide guidance through complex valuation and financial challenges, offering insights that drive business growth and operational efficiency.</li>
      </ul>

      <h2>Why Choose Canada PE Ltd.?</h2>
      <ul>
        <li><strong>Expertise:</strong> Our team comprises industry veterans with extensive experience in asset valuation, financial analysis, and consulting across various sectors.</li>
        <li><strong>Precision:</strong> We employ advanced techniques and methodologies to ensure precise and reliable evaluations.</li>
        <li><strong>Customized Solutions:</strong> We recognize the uniqueness of each industry and business, offering tailored services to meet specific needs and challenges.</li>
        <li><strong>Timely Delivery:</strong> Understanding the importance of timely information in business decisions, we strive to deliver our services efficiently and within the required timeframe.</li>
      </ul>
    </div>
  );
};

export default Services;
