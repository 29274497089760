import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Home from './pages/Home';
import Services from './pages/Services';
const styles = {
  nav: {
    padding: '20px',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px'
  },
  page: {
    padding: '20px',
    textAlign: 'center',
    fontSize: '18px'
  }
};

const App = () => {
  return (
    <Router>
      <nav style={styles.nav}>
        <Link to="/">Home</Link>
        <Link to="/services">Services</Link>
        <Link to="/products">Products</Link>
        <Link to="/contact">Contact</Link>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};


export default App;
