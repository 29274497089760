import React from 'react';
import { styles } from '../css/styles';


const Contact = () => {
  return (
    <div style={styles.page}>
      <h2>Contact Information</h2>
      <p>
        <strong>Contact Person:</strong> Mr. Bill Wang <br />
        <strong>Company Name:</strong> Canada PE Ltd. <br />
        <strong>E-mail:</strong> canadape@gmail.com <br />
        <strong>Website:</strong> <a href="http://www.canadape.com">http://www.canadape.com</a> <br />
      </p>
    </div>
  );
};

export default Contact;
