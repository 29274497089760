export const styles = {
    nav: {
      padding: '20px',
      backgroundColor: '#f0f0f0',
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '20px'
    },
    page: {
      padding: '20px',
      textAlign: 'left',
      fontSize: '18px'
    }
  };